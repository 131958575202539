import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

// 图片文件上传
// 从 STS 服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）、安全令牌（SecurityToken）。
export function uploadIndex(data) {
  return request({
    url: '/backend/web/upload/index',
    method: GET,
    data
  });
}
export const getOSSAuth = uploadIndex;

export function getUpVideoAuth(data) {
  return request({
    url: '/backend/web/upload/video',
    method: POST,
    data
  });
}
// 文件删除(头像)
export function uploadDelete(data) {
  return request({
    url: '/backend/web/upload/delete',
    method: POST,
    data
  });
}
